.content_header_settings {
  margin-top: 50px;
  text-align: center;
  padding-bottom: 14px;
}

.settings-icon {
  height: 96px;
  width: 96px;
  padding-bottom: 10px;
  border-radius: 100px;
}

.tab-panel-settings {
  height: calc(100% - 226px);
  overflow: auto;
  background: #ffffff;
}

.personal-settings {
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto;
}

.personal-settings .two-side-by {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.personal-settings .personal-settings-title {
  padding-bottom: 10px;
  padding-top: 10px;
}

.personal-settings.session-settings {
  max-width: 950px;
}

.personal-settings.session-settings .personal-settings-title {
  justify-content: space-between;
}

.personal-settings.password-tab .personal-settings-title {
  justify-content: space-between;
  align-items: baseline;
}

.personal-settings.password-tab {
  max-width: 410px;
  min-width: 250px;
}

.company_table.session_table {
  height: auto;
}

.rectangle-icon {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.disable-btn {
  right: 4px;
}

.dflex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}

.notify-tabs {
  align-items: flex-start;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 15px;
  padding: 14px;
}

/* .ellipse-icon{
    width: 20px;
    height: 20px;
} */

/* dashboard */

.company_grid.dashboard_grid {
  width: calc(100% / 5 - 20px);
  max-width: 210px;
}

.charts_grid {
  width: calc(100% / 2 - 20px);
  /* width: 100%; */
  padding: 20px 20px;
  background: #ffffff;
  /* border: 0.82619px solid #e0e0e0; */
  box-sizing: border-box;
  border-radius: 11.5667px;
  /* height: inherit; */
  box-shadow: 0px 20px 45px #f0edf7;
  border-radius: 10px;
}

.charts_header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 20px;
}
.charts_title {
  display: flex;
  align-items: center;
}
.charts_title .heading-label {
  padding-inline: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.year_dropdown {
  display: flex;
  align-items: center;
}

.grid_keys.green_text {
  color: #34aa44;
  line-height: 32px;
}

.grid_keys.red_text {
  color: #ff0000;
  line-height: 32px;
}

/* .bar_chart {
  width: min-content;
  display: flex;
  flex-wrap: wrap;
  height: min-content;
} */

.company_content .company_card .upcoming_box {
  align-items: center;
  justify-content: space-between;
  width: 325px;
}

.company_content .company_card .upcoming_box > p {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 3px solid;
  margin: 0;
  margin-bottom: 15px;
}
.company_content .company_card .upcoming_box .interview_box {
  flex-direction: column;
  display: flex;
  margin-bottom: 15px;
  text-align: left;
  padding: 15px;
  gap: 5px;

  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.company_content .company_card .upcoming_box .interview_box span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-right: auto;
  text-transform: capitalize;
  /* color: #101828; */
}

.company_content .company_card .upcoming_box .interview_box div .date_span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #667085;
  margin: 0 !important;
}

.company_content .company_card .upcoming_box .interview_box .job_span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin: 10px 0 10px 0;
}
.company_content .company_card .upcoming_box .interview_box .custom_tag {
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;
  padding: 2px 5px;
}
.company_content .company_card .upcoming_box .interview_box .active_span {
  background: #e3fcef;
  color: #00875a;
}

.company_content .company_card .upcoming_box .interview_box .nonactive_span {
  background: #e1e2e1;
  color: #626b7e;
}

.company_content .company_card .upcoming_box .interview_box .view_all {
  color: #0890fe;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.company_content .footer_rights {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  padding-bottom: 5px;
}

.col-flex {
  display: flex;
  flex-direction: column;
}

.col-flex span {
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #667085;
}

.m-30 {
  margin: 30px;
}

.common-container .inner-container .user-container .user-list.no-padding-cust {
  padding: 0;
}

.tab-panel-calendar {
  height: 76%;
  overflow: scroll;
  /* width: fit-content; */
}

.dashboard_content {
  height: calc(100% - 20px) !important;
}
.grid_container {
  display: flex;
  flex-wrap: wrap;
  /* display: grid; */
  width: 100%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(50% - 20px), min-content)
  );
  gap: 20px;
}
.chart_container {
  height: calc(100% - 70px);
}
.grid_container .jobs,
.grid_container .applicants {
  height: 400px;
}
.flex_container {
  display: flex;
  width: 100%;
  gap: 20px;
  height: 435px;
}
.flex_container .charts_grid:nth-of-type(1) {
  flex: 2;
}
.company_card .upcoming_container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  justify-content: space-between;
  overflow-y: auto;
}
@media only screen and (min-width: 2500px) {
  /* .charts_grid {
    width: 100%;
  } */
}

@media only screen and (max-width: 1396px) {
  /* .charts_grid {
    width: calc(100% / 1 - 20px);
    width: 100%;
  } */
}

@media only screen and (max-width: 1396px) {
  .company_grid.dashboard_grid {
    width: calc(100% / 4 - 20px);
  }
}

@media only screen and (max-width: 996px) {
  .company_grid.dashboard_grid {
    width: calc(100% / 2 - 20px);
  }
}

@media only screen and (max-width: 640px) {
  .company_grid.dashboard_grid {
    width: calc(100% / 1 - 20px);
    min-width: 170px;
  }
}

.title_3 {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #667085;
  margin-top: 3px;
  margin-bottom: 6px;
}

.notify-card {
  align-items: baseline;
}

@media only screen and (max-width: 1200px) {
  .flex_container {
    flex-direction: column;
    height: auto;
  }
  .flex_container .charts_grid.bar_chart {
    height: 450px;
  }
  .flex_container .charts_grid:nth-of-type(1) {
    flex: none;
  }
}

@media only screen and (max-width: 1100px) {
  .charts_grid {
    width: 100%;
  }
}

.forms-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.forms-input.curr-status {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    /* width: 180px; */
}

.forms-input label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
    color: #000000;
    margin-left: 0px;
}

.in-forms .max-applicant {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D1C1D;
}

.forms-input textarea {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 24px;
    background-color: #fff;
}

.forms-input select {
    max-width: 100%;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 22px;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 8px;
}

.forms-input input[type='text'],
input[type="password"],
input[type="number"] {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 22px;
    background-color: #fff;
}

.forms-inputinputnumber {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 14px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    color: #101828;
    outline: none;
    line-height: 22px;
    background-color: #fff;
}

.sections {
    flex-direction: row;
    justify-content: space-between;
}

.col-2 {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.template-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

label {
    font-family: 'Inter';
}

div {
    font-family: 'Inter';
}

span {
    font-family: 'Inter';
}

label span {
    font-weight: 600;
}

.status {
    background-color: #9EA0A5;
    color: #FFFFFF;
    padding: 6px;
    border-radius: 15px;
    font-size: 9px;
    text-align: center;
    font-weight: 600;
    width: fit-content;
}

.blue-completed {
    background-color: #1665D8;
}

.green-active {
    background-color: #34AA44;
}

.greyColour {
    color: #828282;
}

.red-delete {
    background-color: #800000;
}

.orange-sign {
    background-color: #edb90c
}

.light-blue {
    background-color: #0ab7f0;
}

.error_msg {
    font-style: normal;
    font-weight: 400;
    color: red;
    font-size: 14px;
    /* margin: 5px 0 0 5px; */
    line-break: normal;
    padding-top: 5px;
    font-family: 'Inter';
}

.success_msg {
    font-style: normal;
    font-weight: 400;
    color: green;
    font-size: 12px;
    /* margin: 5px 0 0 5px; */
    line-break: normal;
    padding-top: 5px;
    font-family: 'Inter';
}

.col-3 {
    display: flex;
    flex-direction: column;
    width: 31%;
}

.ml-10 {
    margin-left: 10px;
}

.MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
    text-align: center;
    height: 58px;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
}

.status-border {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
}

.status-border legend {
    text-align: right;
}

.interview-section {
    border-bottom: 1px solid #D0D5DD;
    padding-bottom: 10px;
    width: 100%;
}

/* fieldset{
    border: 6px solid transparent; 
    border-top-color: black; 
    box-sizing: border-box; 
    grid-area: 1 / 1; 
    padding: 20px; 
    width: 200px; 
  }

  fieldset:nth-of-type(2){ transform: rotate(90deg); }

  legend{
    font: 9pt/0 'Averia Serif Libre';
    padding: 0 4px; 
  }

  .d1 legend{ margin-left: auto; } */
.job-data-container {
    background: #FFFFFF;
}

.job-data-container .header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.job-data-container .header img {
    padding: 20px;
}

.job-data-container .j-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
}

@media (max-width: 400px) {
    .apply-for-job {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
    }
}

@media screen and (min-width: 400px) and (max-width: 767px) {
    .apply-for-job {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
    }
}

@media (max-width: 1250px) {
    .apply-for-job {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 60px;
    }
}

@media (min-width: 1250px) {
    .apply-for-job {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 240px;
    }
}

.physical-request-container {
    text-align: left;
    height: fit-content;
}

.apt-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.question-title {
    font-size: 14px;
    margin: 14px 14px 14px 0px;
    text-align: left;
}

.rating-video {
    width: 600px;
    height: 350px;
    display: block;
}

.white-container.form {
    width: 65%;
}

.doc-link {
    font-weight: 600;
    text-decoration: underline;
}

.doc-link:hover {
    color: #12db18;
}
.d-item {
    display:flex;
    flex-direction: column;
    width: 88%;
    padding: 13px
}

.d-item div {
    display: flex;
    width: 45%;
    justify-content: space-between;
}

.d-item span {
    font-size: 0.87rem;
    color: #667085;
}

.d-item strong {
    color: rgba(0, 0, 0, 0.87);
}

.d-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

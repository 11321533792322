.user-details-tab {
  width: 100%;
  overflow: unset;
  height: max-content;
  background: #ffffff;
  border-radius: 14px;
}

.applicant-tab-main-container {
  width: calc(100% - 366px);
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: fit-content;
}

.tabs {
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 6px;
  border-radius: 8px;
  margin-block: 4px;
}

.tab-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 8px; */
  min-height: max-content;
}

.applicant-chat .tab-container {
  height: 100%;
}

.applicant-chat .tab-container .heading-label {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background: var(--localnav-background-stuck, rgba(255, 255, 255, 0.2));
  height: 30px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  border-bottom: 1px solid #fff;
  position: sticky;
  top: 0px;
  width: calc(100% - 20px);
  min-height: 50px;
  z-index: 1;
}

.applicant-chat .tab-container .text-area-container {
  width: calc(100% - 100px);
  border: none;
  padding: 0;
  border-radius: 0;
  display: unset;
}

.heading-label {
  font-weight: 600;
  font-size: 14px;
}

.resume-content-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.resume-content-container.file-upload {
  flex-direction: column;
}

.resume-details {
  display: flex;
  flex: 1;
  align-items: center;
  /* height: 46px; */
}

.cv-icon-container {
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.cv-icon-container.pdf {
  background-color: #ffebeb;
}

.cv-icon-container.doc {
  background-color: #deebff;
}

.cv-icon-container.img {
  background-color: #d4d5d6;
}

.resume-content-container .MuiDropzoneArea-root {
  min-height: 150px;
}

.job-document.resume {
  justify-content: flex-end;
}

.details-container {
  display: flex;
  flex: 2;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 20px;
  height: 50px;
}

.resume-name {
  font-size: 12px;
  font-weight: 600;
  word-break: break-all;
}

.resume-status {
  font-size: 12px;
  font-weight: 500;
  color: #828282;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 130px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.icon-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 56px;
}

.cv-icon-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 30px;
}

.icon-border-right {
  border-right: 1px solid #f2f2f2;
}

.icon-label {
  font-size: 12px;
  color: #9ea0a5;
  padding-top: 4px;
}

.notes-header-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  justify-content: space-between;
}

.hot-app-label {
  font-weight: 600;
  border-radius: 15px;
  padding: 4px;
  width: 90px;
  background: #9EA0A5;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
}

.hot-app-label.red {
  background: #b71c1c;
}

.notes-cards-container {
  display: flex;
  flex: 1;
  overflow: auto;
  padding-right: 8px;
  /* margin-top:8px; */
  max-height: 320px;
  flex-direction: column;
}

.notes-card {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  padding: 10px 14px;
  border-radius: 12px;
  margin-top: 12px;
  word-break: break-word;
}

.card-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.name-label {
  display: flex;
  flex: 1;
  color: #101828;
  font-size: 14px;
  font-weight: 600;
}

.header-detail {
  display: flex;
  /* flex:1; */
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 16px; */
}

.header-labels {
  color: #98a2b3;
  font-size: 12px;
}

.dot-div {
  height: 8px;
  width: 8px;
  margin-inline: 8px;
  border-radius: 50%;
  background-color: #98a2b3;
}

.sm-dot-div {
  height: 4px;
  width: 4px;
  margin-inline: 8px;
  border-radius: 50%;
  background-color: #98a2b3;
}

.dot-div.filter {
  background-color: #bac0cc;
}

.dot-div.selected {
  background-color: #3954a5;
}

.notes-desc {
  color: #475467;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.doucments-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 12px;
  margin-top: 16px;
}

.doc-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.doc-title {
  display: flex;
  justify-content: space-between;
}

.file-name-label {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin-left: 8px;
  word-break: break-all;
  font-size: 14px;
}

.doc-icon {
  margin-right: 16px;
}

.checkbox-container.applicants {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #fafafa;
  padding: 4px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  /* margin-top: 16px; */
}

.hot-applicant-note-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}

.text-area-container {
  display: flex;
  flex: 1;
  border: 1px solid #9494ae;
  padding: 8px;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 12px;
}

.applicant-btn-container {
  display: flex;
  margin-top: 16px;
}

.hot-list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9ea0a5;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-right: 6px;
}

.chat-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  /* padding-right: 8px; */
  max-height: 320px;
  /* margin: 0 16px; */
  /* background-color: #f6f6f8; */
}

.chat-container.applicant {
  max-height: 100%;
  margin: 0 0;
  position: relative;
}

.message-chat-container {
  display: flex;
  flex: 1;
  margin-block: 6px;
}

.chat-container.applicant .message-chat-container {
  flex: unset;
}

.chat-container.applicant .message-chat {
  margin: 12px;
  padding: 13px 10px;
}

.message-chat {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  padding: 6px 12px;
  border-radius: 8px;
  height: fit-content;
  word-break: break-word;
}

.chat-design {
  position: absolute;
  height: 20px;
  width: 20px;
}

.chat-container.applicant .message-text {
  margin-top: 9px;
  font-size: 14px;
  margin-bottom: 17px;
}

.chat-text {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
}

.chat-text input {
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px 12px;
  margin-right: 10px;
}

.message-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  color: #333333;
}

.chat-container.applicant .sender-label {
  /* margin-top: -35px !important; */
}

.chat-container.applicant .chat-footer {
  margin-bottom: -35px !important;
}

.applicant-chat .attach-send {
  margin-left: 20px;
}

.sender-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #556ea7;
}

.sender-label.applicant {
  color: #909cb5;
}

.chat-footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  max-height: 24px;
}

.chat-footer-detail {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.chat-footer-labels {
  color: #666668;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}

.chat-main-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attach-send {
  display: flex;
  align-items: center;
}

.attachment-container {
  display: flex;
  /* width: calc((100% - 40px) / 2); */
  flex-wrap: wrap;
  /* flex-direction: row; */
  /* display: grid;
  grid-template-columns: 25% 25% 25% 25%; */
}

@media screen and (min-width: 350px) and (max-width: 450px) {
  .attachment-container {
    /* grid-template-columns: 25% 25%; */
  }
}

@media screen and (min-width: 350px) {
  .attachment-container {
    /* grid-template-columns: 25%; */
  }
}

@media screen and (min-width: 510px) and (max-width: 680px) {
  .attachment-container {
    /* grid-template-columns: 25% 25%; */
  }
}

.attachemt-item {
  padding: 8px;
}

.attachment {
  display: flex;
  flex-direction: row;
  padding: 2px;
  align-items: center;
  justify-content: space-between;
  background-color: #f6f6f8;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  width: 160px;
}

.attachment-close-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.applicant-chat .chat-quick-note-container {
  flex-direction: row;
  padding: 0 15px;
}

.chat-quick-note-container {
  display: flex;
  flex-direction: column;
  margin-block: 12px;
}

.chat-quick-note-container span {
  font-size: 14px;
}

.review-edit {
  display: flex;
  /* width: 144px; */
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.multiple .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
  display: flex;
  flex-direction: row;
}

.forms-input span.mt-10 {
  text-align: right;
  font-size: 0.87rem;
  font-weight: 600;
  color: #006aff;
}

.time-slots {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.time-slots .slot-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  height: calc(100% - 30px);
}

.time-slots .slot {
  font-size: 10px;
  padding: 4px;
  background: #f2f5ff;
  border-radius: 5px;
  border: 1px solid #3954a5;
  color: #3954a5;
}

.time-slots .b-slot {
  font-size: 10px;
  padding: 4px 6px 4px 6px;
  background: #f2f5ff;
  border-radius: 5px;
  color: #3954a5;
}

.time-slots .inter-name {
  display: flex;
  align-items: center;
  width: 130px;
}

.inter-name {
  display: flex;
  align-items: center;
  width: 130px;
  line-break: anywhere;
}

.time-slots .slot:hover {
  background: #3954a5;
  color: #f2f5ff;
}

.time-slots .slot.selected {
  background: #3954a5;
  color: #f2f5ff;
}

.time-slots .slot.disabled {
  background: #d1d5db;
  color: #52525b;
  border: 1px solid #52525b;
}

.ml-20 {
  margin-left: 20px;
}

.applicant-chat {
  width: calc(100% - 50px);
  max-height: calc(100% - 100px) !important;
  max-width: 768px;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 0 0 0;
  border-radius: 20px;
  overflow: hidden;
  background: #FFFFFF;
}

/* .chat-container.applicant {
    min-height: 520px;
} */

.chat-container .no-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 190px 0px 190px 0px;
}

.resume-content-container .MuiDropzoneArea-root .MuiDropzoneArea-textContainer {
  text-align: center;
  height: 58px;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
}

.MuiDropzoneArea-textContainer .MuiTypography-h5 {
  margin-left: 15px;
}

.participants {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;
  color: #006aff;
  font-weight: 600;
  align-items: center;
}

.participants.add {
  margin-top: 0px;
}

.sign-label {
  color: #006aff;
}

.applicant-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 5px 10px;
  background: #e4f0ff;
}

.job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 5px 10px;
  background: #deebff;
}

.job-header strong {
  flex: 1;
}

.common-container .inner-container .user-container .user-list.applicant-h {
  background: #ffffff;
  box-shadow: inset 0 15px 0 0 #e4f0ff;
  position: relative;
}

.common-container .inner-container .user-container .user-list.job-h {
  background: #ffffff;
  box-shadow: inset 0 15px 0 0 #deebff;
  position: relative;
}

.user-details-tab.applicants {
  height: calc(100% - 400px);
}

.inner-h.job-sidebar.no-data {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: calc(100% - 60px) !important;
}

.inner-h.job-sidebar.no-data img {
  width: 200px;
  margin-top: 50px;
}

@media screen and (max-width: 1538px) and (max-height: 614px) {
  .inner-h.job-sidebar.no-data {
    max-height: 226px;
    overflow-y: scroll;
  }
}

.checkbox-eye {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chat-icon {
  margin: auto;
  font-size: 20px;
}

.chat-icon-label {
  font-size: 12px;
  padding: 0 26px;
  text-align: center;
}

.no-message-container {
  background: #f6f6f8;
  margin: 0 16px;
}

.user-list.applicant-h .column-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 30px);
}

.user-list.job-h .column-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 30px);
}

.calendar-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.cal-title {
  color: #667085;
  font-weight: 600;
  margin-right: 10px;
}

.calendar-card label {
  color: #98a2b3;
}

.searchbox {
  position: relative;
  min-width: 30px;
  width: 0%;
  height: 38px;
  float: right;
  overflow: hidden;
  display: flex;
  /* background-color: #fff; */
  border-radius: 8px;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}

.searchbox-input {
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  width: 90%;
  height: 34x;
  margin: 0;
  padding: 0px 0px 0px 10px;
  font-size: 14px;
  color: #3954a5;

}

.searchbox-input::-webkit-input-placeholder {
  color: #9494ae;
}

.searchbox-input:-moz-placeholder {
  color: #9494ae;
}

.searchbox-input::-moz-placeholder {
  color: #9494ae;
}

.searchbox-input:-ms-input-placeholder {
  color: #9494ae;
}

.searchbox-icon,
.searchbox-submit {
  width: 32px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: verdana;
  font-size: 18px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  line-height: 10px;
  text-align: center;
  cursor: pointer;
  color: #3954a5;
}

.searchbox-open {
  width: 95%;
  margin-left: 4px;
  background: #fff;
}

@media only screen and (max-width: 768px) {
  .applicant-chat {
    max-height: calc(100% - 35px) !important;
  }
}

.attach-icon {
  background-color: #AABFEC;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  padding: 10px;
}

.attach-data {
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.attach-size {
  color: #666668;
}
.notification-container{
    padding: 30px 32px;
    height: calc(100% - 60px);
    overflow: auto;
}
.notification-container .cards-notifi{
    background: #FFFFFF;
    border: 1px solid #EDF2FB;
    border-radius: 14px;
    padding: 12px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 14px;
}
.notification-container .cards-notifi.active{
    background: #EDF2FB;
    border-color: #EDF2FB;
}
.notification-container .cards-notifi .cards-name{
    margin-left: 16px;
    width: 100%;
}
.notification-container .cards-notifi .profile-notification{
    width: 66px;
    height: 65px;
    border-radius: 50%;
}
.notification-container .cards-notifi .cards-name .cards-heading p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D1C1D;
    margin: 1px 0 10px 0;
}
.notification-container .cards-notifi .cards-name .cards-heading p strong{
    color: #9EA0A5;
    font-weight: normal;
}
.notification-container .cards-notifi .cards-name .cards-notifi-time{
    color: #9EA0A5;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    text-align: right;
}
.video-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.poster-video {
    display: block;
    height: 236px;
}

.video-question {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.MuiButtonBase-root.MuiAccordionSummary-root.css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 0px;
}

.MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content {
    margin: 0px;
    min-height: 0px;
}

.rating-label {
    padding: 4px;
    background-color: #FFF8D3;
    border-radius: 6px;
    font-size: 12px;
    height: 16px;
    min-width: 36px;
}

.all-comments {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.padding-top-20 {
    padding-top: 60px;
    /* Adjust the value as needed */
}

.video-col-container {
    position: relative;
}

.all-comments label {
    color: #AFAFAF;
}

.inForms.rate {
    margin-top: 0px;
}
/* loader css */
.loader {
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.circular {
    animation: rotate 2s linear infinite;
    height: 60px;
    transform-origin: center center;
    width: 60px;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

.text-loader {
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {

    100%,
    0% {
        stroke: #d62d20;
    }

    40% {
        stroke: #0057e7;
    }

    66% {
        stroke: #008744;
    }

    80%,
    90% {
        stroke: #ffa700;
    }
}

@keyframes color-white {

    100%,
    0% {
        stroke: #fff;
    }

    100% {
        stroke: #fff;
    }
}

.showbox {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    pointer-events: auto;
    z-index: 9999;
}

/* .showbox.hide{
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  } */

/* loader css end */


.item-enter {
    opacity: 1;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 00ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
/* @font-face {
	font-family: "Roboto-Regular";
	src: url("/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Roboto-Bold";
	src: url("/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Roboto-Medium";
	src: url("/fonts/Roboto-Medium.ttf") format("truetype");
} */

#aspnetForm {
	font-family: Helvetica, sans-serif;
	font-size: 10pt;
}

h1 {
	margin-right: 0px !important;
	margin-left: 0pt !important;
}

p {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.cal_cell {
	text-align: center;
	padding: 4px 0 4px 0;
	font-size: 9pt;
	font-weight: bold;
	border: 1px solid #FFF !important;
	cursor: pointer;
}

	.cal_cell.active {
		border: 1px solid #B25050 !important;
	}

	.cal_cell.selected {
		background-color: #28AC58;
		border: 1px solid #28AC58;
		color: #FFF;
	}

	.cal_cell.clicked {
		color: #B25050;
	}

	.cal_cell:hover {
		border: 1px solid #28AC58;
	}

	.cal_cell.empty {
		cursor: default;
		color: #DDD;
	}

		.cal_cell.empty:hover {
			border: 1px solid #FFF;
			cursor: default;
		}

.cal_days {
	text-align: center;
	font-size: 9pt;
	font-weight: bold;
	padding-top: 6px;
}


.checked {
	position: absolute;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8cGF0aCBkPSJNNDUyLDUxMkg2MGMtMzMuMSwwLTYwLTI2LjktNjAtNjBWNjBDMCwyNi45LDI2LjksMCw2MCwwaDM5MmMzMy4xLDAsNjAsMjYuOSw2MCw2MHYzOTJDNTEyLDQ4NS4xLDQ4NS4xLDUxMiw0NTIsNTEyeg0KCSBNNjAsNDBjLTExLDAtMjAsOS0yMCwyMHYzOTJjMCwxMSw5LDIwLDIwLDIwaDM5MmMxMSwwLDIwLTksMjAtMjBWNjBjMC0xMS05LTIwLTIwLTIwSDYweiBNNDMwLjksMTUxLjNsLTI5LjgtMjYuN2wtMTg1LDIwNi42DQoJbC0xMDcuNC0xMDJsLTI3LjYsMjlsMTM3LjIsMTMwLjRMNDMwLjksMTUxLjN6Ii8+DQo8L3N2Zz4NCg==");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	width: 16px;
	height: 16px;
}

.doc_link {
	text-decoration: underline;
	cursor: pointer;
}

	.doc_link:hover {
		color: #28AC58;
	}


.doc_wrapper {
	background-color: #FFF;
	padding: 10px;
	color: #000;
	opacity: 0;
}

	.doc_wrapper.shown {
		opacity: 1;
	}



.filter_item {
	display: inline-block;
	font-size: 9pt;
	font-weight: normal;
	color: #000;
	background-color: #FFF;
	padding: 2px 16px 2px 2px;
	border: 1px solid #000;
	margin-right: 4px;
	margin-top: 2px;
	white-space: nowrap;
	/* background-image: url(/images/closeB3.svg); */
	background-position: center right 2px;
	background-repeat: no-repeat;
	background-size: 12px;
	cursor: pointer;
}


/* .icon {
	width: 24px;
	height: 24px;
	cursor: pointer;
	vertical-align: top;
} */


/* input[type="text"], input[type="password"], input[type="email"] {
	font-family: Roboto-Regular, Tahoma, Geneva, Verdana, sans-serif;
	font-size: 10pt;
	box-sizing: border-box;
	border: 1px solid #000;
	padding: 0;
	color: #000;
	height: 1.5em;
	cursor: context-menu;
}

	input[type="text"].underline {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #000;
	}

	input[type="email"].underline {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 1px solid #000;
	}

	input[type="text"].noBorder {
		border: none;
	}

	input[type="email"].noBorder {
		border: none;
	} */


	input[type="text"].signature_field {
		/* font-family: Roboto-Regular, Tahoma, Geneva, Verdana, sans-serif; */
		font-size: 10pt;
		box-sizing: border-box;
		border: 1px solid #000;
		padding: 0;
		color: #000;
		height: 1.5em;
		cursor: context-menu;
		width: 100%;
		max-width: 220px;
		border: 1px solid #B25050 !important;
		padding-left: 50px !important;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAMCAYAAADLeFVvAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAIOSURBVEhLzZUhUMNAEEUjEAhERUUFohJRgaxAVCAQFQgEAlmJQCIq6hHIygoEAolAIMpMRSUCgahAICoQCERFRXj/ZjdzCQmFDgLxcvv3dje3yV2SpGn6b3lIkk6Zv0hmjJOkDj0Sb2AMV+g9zZl2LqOcDcu5trk7dB8alncpv9dxzLcb+8ogLlUsfNtMuFBwk8BnmGD34AT7SgVsvoP9CCO/ueXcwwv2KezDGXqmOMUwagELjdIOOiV25VNWXERlM+FCwQMClozhCTrobbdVBD2I5vr45ox195lfb6ct2248hAW+bAHodZqobCZcKHioAMbKV6xk5rMm0K/o8zimiOcwhm0V+b80gU9xRcqacLJmQgEKamtou+jVD9HHUCvcJGvC4rWQbhxTxHOgodqM4aaWW2yibKE/YZAVoegWaE/fwge8oY+im8RN1NG5haAn8GJclOToyU7N//dvogg3qBGgL87cfUryBZl+R/dcY7e1MPx3kB1sz2FUTeX41s01UYbiSqg8E7mtY76uknxOydhxE1ps7qtj/pEwO5cjG5+27TpNfFm8Ey5WXJ/PlumWJU08UFpxrrF38C0tL3zVGHVW9J+pakJvYw6/aaJy8U64UHCfwCdLcqb4m5ov+LOnz3wbrf+C/K82zvCHs4Sda8JydO5+2sTKGJETFK5BE3Lf/lUoHnQmfpX3N6TJJ5/XIYHc1QRLAAAAAElFTkSuQmCC");
		background-position-x: left;
		background-position-y: center;
		background-repeat: no-repeat;
	}


.menu {
	display: none;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 10000px;
	background-color: #FFF;
	border: 1px solid #000;
	padding: 5px;
	z-index: 12000;
	text-align: left;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: thin;
}

.menu_closer {
	text-align: right;
	margin-bottom: 20px;
	padding-right: 4px;
}

.menu_item {
	font-family: Roboto-Medium, Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 8px 15px;
	font-size: 10.5pt;
	background-color: transparent;
	color: #000;
	cursor: pointer;
	white-space: nowrap;
	z-index: 12001;
}

	.menu_item:hover {
		background-color: #000;
		color: #FFF;
	}

.menu_scroll {
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	scrollbar-width: thin;
}

	.menu_scroll::-webkit-scrollbar {
		width: 4px;
		background-color: #F5F5F5;
	}

	.menu_scroll::-webkit-scrollbar-thumb {
		background-color: #466492;
		border-radius: 2px;
	}

		.menu_scroll::-webkit-scrollbar-thumb:hover {
			background-color: #000;
		}


.oneLiner {
	height: 2.2em;
	resize: none;
	overflow-y: hidden;
}

.report_header {
	font-size: 14pt;
	font-weight: bold;
}

select {
	font-size: 10pt;
	padding: 2px;
}

.txtarea * {
	vertical-align: top;
}

textarea {
	font-family: Roboto-Regular, Tahoma, Geneva, Verdana, sans-serif;
	font-size: 10pt;
	border: 1px solid #000;
	box-sizing: border-box;
	padding: 2px;
	cursor: context-menu;
}

	textarea.multi.full_width {
		width: 100%;
		overflow: hidden;
		resize: vertical;
	}

	textarea.multi.set_width {
		resize: both;
	}

	textarea.single.full_width {
		width: 100%;
		overflow: hidden;
		resize: none;
	}

	textarea.single.set_width {
		overflow: hidden;
		resize: horizontal;
	}

	textarea.no_border {
		border: 1px dashed #000;
	}

	textarea.bottom_border {
		border: none;
		border-bottom: 1px solid #000;
	}


	.view textarea {
		resize: none !important;
	}

		.view textarea.multi {
			overflow-y: auto !important;
		}

		.view textarea.no_border {
			border: none;
			padding: 0;
		}


table {
	border-collapse: collapse;
	width: 100%;
	font-size: 10pt;
}

	table.small {
		width: initial;
	}

	table.border {
		border: 1px solid #000;
	}

/*td {
	padding: 2px 4px 2px 4px;
}*/

table.border td {
	border: 1px solid #000;
}

.tadiv {
	display: block;
	width: 100%;
	border: 1px solid #000000;
	padding: 3px;
	box-sizing: border-box;
	color: #000;
}

	.tadiv.no_border {
		border: none;
		padding: 0;
	}

	.tadiv.bottom_border {
		border: none;
		border-bottom: 1px solid #000;
	}

.tadiv_set_width {
	border: 1px solid #000000;
	padding: 3px;
	box-sizing: border-box;
	color: #000;
}

	.tadiv_set_width.no_border {
		border: none;
		padding: 0;
	}

	.tadiv_set_width.bottom_border {
		border: none;
		border-bottom: 1px solid #000;
	}


td.list {
	padding-right: 5px;
}

td.listCenter {
	padding-right: 2px;
	padding-left: 2px;
	text-align: center;
}

td.listRight {
	padding-right: 5px;
	text-align: right;
}

td.sort {
	cursor: pointer;
	font-weight: bold;
	vertical-align: bottom;
}

	td.sort:hover {
		background-color: #EEE;
	}

td.sortOff {
	font-weight: bold;
	vertical-align: bottom;
}

td.sorted {
	background-color: #EEE;
	font-weight: bold;
	vertical-align: bottom;
}

.text_line {
	width: 100%;
	margin-bottom: 10px;
	display: flex;
}

	.text_line input {
		width: 100%;
		flex-grow: 1;
	}

	.text_line label {
		margin-right: 6px;
		white-space: nowrap;
		padding-top: 8px;
	}


@media print {
	.no_print {
		display: none !important;
	}

	.filter_item {
		display: inline-block;
		font-size: 9pt;
		font-weight: normal;
		color: #000;
		background-color: #FFF;
		padding: 2px;
		border: 1px solid #000;
		margin-right: 4px;
		margin-top: 2px;
		white-space: nowrap;
	}
}

@media screen {
	.no_screen {
		display: none !important;
	}
}
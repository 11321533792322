.white-container {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    margin: auto;
    display: block;
    border: 1px solid #D0D5DD;
    text-align: center;
    font-size: 14px;
    top: 90px;
    width: calc(100% - 100px);
    height: calc(100vh - 150px);
    max-width: 600px;
    overflow: auto;
    max-height: 690px;
}

.txt_box{
    padding-top: 0px;
    margin-bottom: 10px;
}

.input-container {
    margin: auto;
    width: 50%;
}

.white-container label {
    word-break: break-word;
}

.doc-tab-container {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 30px;
}

.doc-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}


@media only screen and (max-width: 768px) {
    .white-container {
      top: 15px;
      width: calc(100% - 70px);
      height: calc(100vh - 70px);
      max-height: none;
    }
}
.job-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: calc(100% - 130px);
    margin-bottom: 15px;
    padding-right: 10px;
    overflow: auto;
    align-content: baseline;
}

.job-grid {
    padding: 15px 15px;
    background: #FFFFFF;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-template-columns: auto auto;
    max-height: 282px;
    width: 100%;   
    transition: 0.5s all;
    align-items: center;
}

.job-right {
    display:flex;
    justify-content: space-around;
    width: 20%;
    align-items: center;
}

.job-right div {
    margin-bottom: 0px;
}

.job-right div select {
    padding: 5px 10px;
    padding-right: 35px;
}

.job-title {
    color: #667085;
    font-size: 12px;
}

.job-board-title {
    font-weight: bold;
}

.job-t-h-1 {
    display: flex;
    justify-content: space-between;
}

.job-t-h-2 {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #E0E0E0;
}

.job-t-h-2 span {
    font-weight: bold;
    margin-top: 10px;
}

.job-t-h-2 p {
    text-align: justify;
    font-size: 13px;
    color: #667085;
}

.job_template_option_menu {
    list-style: none;
    padding-inline-start: 0px;
    margin-top: 10px;
    height: calc(100% - 40px);
    overflow: auto;
}

.infinite-scroll-component__outerdiv{
    height: 100%;
    overflow-y: auto;
}

.job_template_option_menu.sd{
    height: calc(100% - 152px);
}

.item {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    line-height: 35px;
    padding: 4px 10px 4px 10px;
    font-weight: 500;
}

.item.selected {
    background: #F2F5FF;
    border-radius: 8px;
    color: #3954A5;
}

.count {
    background: #84CAFF;
    width: 16%;
    height: 32px;
    margin: 5px 0px 5px 0px;
    border-radius: 7px;
    text-align: center;
    color: #FFFFFF;
}

.count.selected {
    background: #3954A5;
    color: #FFFFFF;
}

.count.green {
    background-color: #34AA44;
    color: #FFFFFF;
}

.count.zero {
    background: #eaeaea;
    color: #333333;
}

.description {
    padding: 4px 10px 0px 10px;
    margin: 5px 0px 0px 0px;
}

.main_content {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.field {
    color: #9EA0A5;
    font-weight: bold;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.common-container.users .inner-container .user-detail .personal-info.job-t {
    padding: 20px 0px;
    border: 0;
}

.blue-t {
    color: #2E90FA;
    font-weight: bold;
}

.mb-15 {
    margin-bottom: 15px;
}

.job-template .MuiCheckbox-colorSecondary.Mui-checked {
    color: #3954A5;
}

.job-template {
    display: flex;
    flex-direction: column;
}

.checkbox-label {
    border-bottom: 1px solid #E0E0E0;
    padding: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.checkbox-label .chk-email {
    font-size: 12px;
    margin-left: 40px;
    color: #828282
}

.checkbox-label .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 10px;
    word-break: break-all;
    font-size: 14px;
}

.checkbox-label.job-list .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 0.93rem;
    font-weight: 600;
}

.checkbox-label.job-list {
    display: flex;
    flex-direction: column;
}

.checkbox-label.job-list .company-name {
    margin-left: 45px;
    color: #101828;
}

.checkbox-label.job-list .company-address {
    margin-top: 3px;
    margin-left: 45px;
    /* margin-bottom: 6px; */
    color: #667085;
}

.pointer {
    cursor: pointer;
}

h4 {
    margin-bottom: 5px;
}

.job-temp {
    width: 25%;
    margin-top: 0px;
    display: flex;
    justify-content: center;
}

.activity-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.activity-card {
    width: calc(50% - 60px);
}

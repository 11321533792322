  ------------------------------------------new-----------------------------------------------

  
.main-container{
    height: 100%;
    width: 100%;
}
.main-container .inner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.rec4{
    width: 34%;
    height: 100%;
    background: #fff;
}
.company-design p{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    color: #1D1C1D;
    max-width: 200px;
}
.company-design img{
    margin-right: 20px;
    width: 60px;
    height: 60px;
}
.rec5{
    width: 66%;
    height: 100%;
    background: #112455;
    background-image: url("../../../images/img-right.png");
    background-blend-mode: luminosity;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}
.rec5.active{
    background-image: url("../../../images/img-right-2.png");
}

.right_img{
    width: 100%;
    height: 100%;
    mix-blend-mode: luminosity;
}
.left_logo.active{
    margin-bottom: 78px;
}
.logo_img{
    height: 60.95px;
    width: 250px;
}

.left_form{
    height: 100%;
    width: calc(100% - 40px);
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    margin: 0 auto;
    overflow: auto;
}

.login_title{
   width: 100%;
   padding: 78px 0 56px 0;
}
.login_title.active{
    padding: 20px 0 56px 0;
}

.title_1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    padding: 0;
    margin: 0;
    color: #333333;
}
.title_2{
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #667085;
    margin-top: 3px;
    margin-bottom: 15px;
}

.login_form{
    width: 100%;
}

.login_copyright{
    max-width: 340px;
    margin-top: 24px;
    text-align: center;
}

.copyright_text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    /* or 154% */
    color: #475467;
}

.forgot_box{
    width: 100%;
    padding-top: 20px;  
    display: flex;
}
.email_box{
    padding-top: 0px;
    margin-bottom: 30px;
}

.email_label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */


    /* Text/Black */

    color: #585757;
}

.email_input{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    /* position: absolute; */
    width: 100%;
    height: 44px;
    left: 150px;
    margin-top: 10px;
    padding: 10px 14px;
}
input[type="text"], input[type="password"]{
    outline: none;
}

.pswd_input
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    margin-top: 10px;
    padding: 10px 14px;
}


.login-chekbox {
    border: 2px solid #6D6E72;
    background: #FFFFFF;
}

.login-remembar {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #6D6E72;
}

.login-forgot {
    margin-left: auto;
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #2E90FA;
}

    .login-forgot:hover {
        cursor: pointer;
        text-decoration: none;
        color: #2E90FA;
    }
    
.submit_box{
    /* position: absolute; */
    width: 100%;
    height: 52px;
    padding-top: 8px; 
}
    input[type=button] {
        outline: none;
    }

    .login-submit-btn {
        width: 100%;
        height: 44px;
        background: #3954A5;
        border-radius: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        border-style: none;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        border: none;
        outline: none;
    }
    
.login-submit-btn:hover {
     cursor: pointer;
 }
    
.error_msg{
    font-style: normal;
    font-weight: 400;
    color: red;
    font-size: 14px;
    /* margin: 5px 0 0 5px; */
    line-break: normal;
 }
 span.error_msg{
     padding-top: 5px;
 }


 /* -------------------------------------------Resetpassword css -----------------------------------------------*/

.reset_form{
   width: 100%;
}

.reset_copyright{
    text-align: center;
    max-width: 300px;
    margin-top: 24px;
}

/* -----------------------------------forgotpswd css ---------------------------------------------*/

.center{
    text-align: center;

}
.pswd_box.active{
    margin: 25px 0;
}
.vector_img{
    border-radius: 12px;
}

.back_to_login{
    margin-top: 50px;
}
.login_title.otp-title{
    padding: 32px 0 56px 0;
}
.login_link{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
 
    /* Blue/500 */

    color: #2E90FA;
    text-decoration: none;
    padding-left: 10px;
}


.edit-popup{
    position: absolute;
    right: 0px;
    margin: 0 !important;
    z-index: 999;
}
.edit-popup ul{
    background: #fff;
    list-style: none;
    max-width: 200px;
    height: auto;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
    border-radius: 6px;
    padding-left: 0;
    margin: 0;
    word-break: normal;
}
.edit-popup ul li{
    display: flex;
    align-items: center;
    padding: 6px 14px 8px 8px;
    cursor: pointer;
}
.edit-popup ul li{
    border-bottom: 1px solid #E5E5E5;
}
.edit-popup ul li:last-of-type{
    border-bottom: none;
}
.edit-popup ul li span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
}
.edit-popup ul li > img.actionIcon, img.actionIcon{
    margin-right: 17px;
    width: 22px;
    height: 22px;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
}

html,
#root,
.App,
.App>div {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  /* overflow: hidden; */
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-30 {
  width: 30%;
}

.gap-0 {
  gap: 0px;
}

.gap-20 {
  gap: 20px;
}

.overflow-unset {
  overflow: unset !important;
}

.mt-1 {
  margin-top: 1px;
}

.mt-0 {
  margin-top: 0px;
}

.flex-start {
  align-items: flex-start !important;
}

.red {
  color: red;
}

.red-border>.camera_tag {
  border-color: red;
}

.camera_tag {
  border: 5px solid white;
}

.steps {
  display: none;
}

.steps.active {
  display: block;
}

.cameratag_prompt {
  display: none;
}

.question-p {
  font-size: 21px;
  font-weight: bold;
}

.bold-p {
  font-size: 28px;
  font-weight: bold;
}

.show {
  display: block;
}

.hide {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 0 !important;
  z-index: 12002;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0c0f5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b0c0f5;
}

.text-center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}

.MuiList-root {
  padding-block: 6px !important;
}

.MuiList-root .MuiMenuItem-root {
  padding-block: 4px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.column-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.column-content::-webkit-scrollbar {
  display: none;
}

.MuiIconButton-root {
  padding: 5px !important;
}
.company_content.help-container .content_header{
    /* padding: 36px 30px 27px 30px; */
    padding: 0px 10px 0px 30px;
}
.help-container .company_table{
    margin: 0;
    /* height: calc(100% - 95px); */
}
.company_content.help-container{
    height: calc(100% - 98px);
    border-radius: 14px 14px 0 0;
    padding: 0;
    width: calc(100% - 300px);
    min-width: 350px;
}
.help-container .max-width-container{
    width: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 650px;
}
.help-container .company_table .main-heading{
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
    color: #101828;
    margin: 14px 0 20px 0;
}
.help-container .search-inputs{
    padding: 10px 15px 10px 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    background: #FFFFFF;
    border: 2px solid #1665D8;
    border-radius: 8px;
    color: #000;
    margin-bottom: 15px;
}
.help-container .search-inputs::placeholder{
    color: #9494AE;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.help-container .search-svg{
    position: relative;
    height: 5px;
}
.help-container .search-svg::after{
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    width: 18px;
    height: 18px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../../images/search.svg");
}
/* .help-container .search-svg::before{
    content: "";
    position: absolute;
    top: 16px;
    left: 50px;
    width: 1px;
    height: 25px;
    background-color: #000;
} */
.help-container .results-inputs{
    height: calc(100% - 200px);
    overflow: auto;
}
.help-container .results-inputs > p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin: 9px 0;
    padding: 4px 6px;
    color: #000000;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.8s all;
}
.help-container .results-inputs > p:hover{
    background: #0000ff14;
}
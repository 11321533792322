.App {
  /* text-align: center; */
  font-family: 'Inter';
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.main-container {
  height: 100%;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row;
}

.position-relative {
  position: relative;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.width-auto {
  width: auto;
}

.text-left span {
  font-size: 0.76rem;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-9 {
  font-size: 9px;
}

.d-none {
  display: none;
}

.p-16 {
  padding: 16px;
}

.p-0-16 {
  padding: 0 16px;
}

.p-0-24 {
  padding: 0 24px;
}

.font-blue {
  color: #1665D8;
}

.border-bottom {
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #E0E0E0;
}

.p-border {
  border-bottom: 1px solid #E0E0E0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.ml-4 {
  margin-left: 4px;
}

.doc-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.gap-10 {
  gap: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
}
/* Hide the toolbar and Cancel/OK buttons */
.custom-datepicker .MuiDialogActions-spacing {
  display: none;
}
.PrivatePickersSlideTransition-root {
  /* min-height: 212px !important; */
}
select {
  max-width: 100% !important;
}
.user-list.applicant-h.connectSeq .column-content {
  height: calc(100% - 70px);
}
.content-time {
  font-size: 13px;
}
.remove-margin-span .button-text {
  margin-left: 0 !important;
}
.error-txt {
  color: red;
  font-size: 12px;
  margin: 3px 0 0 5px;
}
.select-or {
  font-size: 12px;
}
.create-msg-content {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #b2b2b2;
  color: #b25050;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.add-msg {
  margin: 0;
  font-size: 14px;
}
.review-text {
  color: #b25050;
  font-size: 12px;
  background: #e6d5d5;
  padding: 6px;
  border-radius: 8px;
}
.seq-start {
  font-size: 14px;
  font-weight: bold;
  color: #b25050;
  margin-top: 20px;
}
.underline-border {
  border-bottom: 1px solid #b2b2b2;
}
.seq-warning {
  margin: 5px 0 15px 0;
  font-size: 13px;
}

/* .column-content select {
    padding: 4px 7px;
    font-size: 14px;
    background-position-y: 4px;
} */

.message-box {
  padding: 10px;
  border-radius: 8px;
  background-color: #e4f0ff;
  font-size: 14px;
  margin: 14px 0px;
}

.message-box select {
  padding: 4px 7px;
  font-size: 12px;
  background-position-y: 4px;
}


/* sidebar */
.side-bar-parent{
    position: absolute;
    height: 100vh;
    background: #fff;
    right: -372px;
    top: 0;
    width: 372px;
    transition: 0.5s all;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
    z-index: 1045;
}
.side-bar-parent.active{
    right: 0;
}
.side-bar-parent header{
    height: 64px;
    padding: 0 25px;
    background: #5E6367;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.side-bar-parent header span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}
.side-bar-parent header > img{
    height: 14px;
    width: 14px;
}
.side-bar-parent .inner-container{
    padding: 30px 26px;
    height: calc(100% - 124px);
    overflow: auto;
}
.side-bar-parent .inner-container .forms-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.side-bar-parent .inner-container .forms-input label{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
    color: #585757;
}

.file-upload {
    background-color: #dbdbdb;
    padding: 10px;
    display: flex;
    border-radius: 7px;
    align-items: center;
}

.file-upload div {
    width: 20%;
}

.file-upload span {
    width: 80%;
}

.side-bar-parent .inner-container input[type="text"],
.side-bar-parent .inner-container select,
.side-bar-parent .inner-container textarea{
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #101828;
    outline: none;
    line-height: 24px;
    background-color: #fff;
}
.side-bar-parent .inner-container .forms-input input[type="text"]::placeholder{
    color: #969696;
}
.side-bar-parent .inner-container select::-ms-expand {
    display: none;
}
.side-bar-parent .inner-container .max-applicant{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1D1C1D;
}
.side-bar-parent .inner-container .two-side-by{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.side-bar-parent .inner-container .two-side-by .forms-input{
    width: calc(50% - 10px);
}
.side-bar-parent .inner-container .drag-drop-container{
    height: 159px;
    background: #F9FAFB;
    border: 1px dashed #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.side-bar-parent .inner-container .drag-drop-container p:nth-of-type(1){
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #585757;
    margin: 16px 0 2px 0;
}
.side-bar-parent .inner-container .drag-drop-container p:nth-of-type(2){
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: #969696;
}
.side-bar-parent .inner-container .btns-container{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.side-bar-parent .inner-container .btns-container button, .side-bar-parent .inner-container .btns-container .add-btn{
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    border: none;
    outline: none;
    cursor: pointer;
}
.side-bar-parent .inner-container .btns-container .add-btn{
    background: #2E90FA;
    color: #fff;
}
.side-bar-parent .inner-container .btns-container .cancel-btn{
    background: #fff;
    color: #101323;
    border: 1px solid #2E90FA;
    padding: 10px;
}
.side-bar-parent .inner-container .upload-file-shower{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    padding: 9px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 51px;
}
.side-bar-parent .inner-container .upload-file-shower .show-img{
   height: 43px;
   width: 43px;
}

.upload-file-shower .show-img{
    height: 43px;
    width: 43px;
 }
.side-bar-parent .inner-container .upload-file-shower span{
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 24px;
   font-family: 'Inter';
   margin-left: 18px;
   color: #585757;
   margin-right: auto;
}
.side-bar-parent .inner-container .upload-file-shower .close-img{
    margin-right: 23px;
    height: 14px;
    width: 14px;
    cursor: pointer;
}
.close-img{
    cursor: pointer;
}
.side-bar-parent .error_msg{
    font-size: 14px;
    margin: 5px 0 0 5px;
}
.back-shadow{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0.5;
    transition: opacity .15s linear;
}
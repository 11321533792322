.col-form-container {
    display: block;
    width: 346px;
    height: calc(100% - 51px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 2;
    cursor: pointer;
    transition: 0.7s all;
    position: absolute;
    margin-top: 51px;
}

.col-form-container header {
    height: 50px;
    padding: 0 10px;
    background: #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* bottom: 0; */
    position: absolute;
    width: 327px;
    transition: 0.7s all;
    bottom: calc(100% - 50px);
    font-size: 14px;
}

header.close {
    bottom: 0;
}

img {
    cursor: pointer;
}

/* span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  } */

.innerContainer {
    height: calc(89% - 124px);
    overflow: auto;
    padding: 30px 25px 26px 30px;
}

.btnContainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
}

.jobTemp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
}

.cancel {
    background: #fff;
    color: #101323;
    border: 1px solid #2e90fa;
    padding: 11px;
    border-radius: 7px;
    cursor: pointer;
    line-height: 24px;
    font-size: 16px;
}

.inForms {
    height: calc(100% - 25px);
    overflow: auto;
    overflow-x: hidden;
    margin-top: 25px;
}

.fullHeight {
    height: 100%;
}

.subBtn {
    background: #2e90fa;
    color: #fff;
}

.fatSubBtn {
    background: #2e90fa;
    color: #fff;
    margin-top: 0px;
    width: 20%;
}

.fatCancel {
    background: #fff;
    color: #101323;
    border: 1px solid #2e90fa;
    padding: 11px;
    border-radius: 7px;
    cursor: pointer;
    line-height: 24px;
    font-size: 16px;
    width: 20%;
}

.inFatForms {
    height: calc(100% - 60px);
    padding-right: 10px;
    overflow: auto;
}

.inForms .forms-input {
    margin-bottom: 8px;
}

.inForms .forms-input label {
    font-size: 13px;
    margin-bottom: 0px;
}

.inForms .forms-input select {
    font-size: 14px;
    padding: 5px 14px;
    background-position-y: 5px;
}

.inForms .forms-input textarea {
    font-size: 14px;
}

.inForms .forms-input input[type="text"],
input[type="password"] {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 14px;
}

.inForms .forms-inputfill {
    margin-bottom: 8px;
    font-size: 14px;
}

.inForms .forms-inputfill label {
    font-size: 14px;
}

.inForms .forms-checkbox .MuiButtonBase-root.MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin: 0px;
}

.inForms .forms-checkbox .MuiCollapse-root .MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiAccordionDetails-root {
    padding: 0px 10px 6px 45px;
}

.inForms label.error_msg {
    font-style: normal;
    font-weight: 400;
    color: red;
    font-size: 12px;
    margin: 5px 0 0 5px;
    line-break: normal;
    padding-top: 5px;
    font-family: "Inter";
}

.doc-type-title {
    font-weight: 600;
    color: #1665d8;
}

.doc-section {
    padding: 20px;
}

.avail-slots {
    height: 200px;
    overflow-y: auto;
}

.add-box {
    padding: 10px;
    margin: 10px;
    border: 1px solid #3954a5;
    border-radius: 8px;
}

.add-box .forms-input label {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0px;
}

.add-box .forms-input input[type="text"] {
    padding: 6px 14px;
    line-height: 14px;
}
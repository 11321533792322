.content_header_reasons {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    justify-content: space-between;
}

.reason_container {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    height: calc(100% - 90px);
    width: calc(100% / 4 - 40px);
}

.reason_grid {
    margin: 10px;
    background-color: #FFFFFF;
    transition: padding 1s;
    padding: 10px;
    border-radius: 6px;
    min-width: 200px
}

@media only screen and (min-width: 1600px) {
    .reason_grid {
        width: calc(100% / 4 - 50px);
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
    .reason_grid {
        width: calc(100% / 3 - 50px);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .reason_grid {
        width: calc(100% / 2 - 50px);
    }
}

@media only screen and (min-width: 780px) and (max-width: 1086px) {
    .reason_grid {
        width: calc(100% / 2 - 50px);
    }
}

@media only screen and (min-width: 650px) and (max-width: 780px) {
    .reason_grid {
        width: calc(100% - 50px);
    }
}

.reason_grid:nth-child(3n + 1) {
    order: 1;
}

.reason_grid:nth-child(3n + 2) {
    order: 10;
}

.reason_grid:nth-child(3n) {
    order: 20;
}

.reason_container:before,
.reason_container:after {
    content: "";
    width: 0px;
    height: 999px;
}

.reason_container:before {
    order: 5;
}

.reason_container:after {
    order: 15;
}

.reason_card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reason_card_header span {
    font-weight: bold;
}

.reason_card_header label {
    font-size: 0.8rem;
}

.reason_card_content {
    text-align: justify;
    margin-top: 10px;
    font-size: 13px;
    color: #667085;
}

.reason_option_menu {
    float: right;
    position: relative;
}
